import React from 'react'
import { linkResolver } from '../prismic/linkResolver'
import { Link } from "gatsby"
import Grid from './Grid'
import Wrapper from './Wrapper'
import UnderlineText from './UnderlineText'
import Img from './CustomImg'
import getTranslations from '../translations'

const CollectionSamples = (props) => {
  const { title, description, meta, imageSrc, samples, enlargedCollection, enlargedSampleImage, lang } = props
  const t = getTranslations(lang);
  return (
    <div id={meta.uid} className="collection-samples">
      <div className="samples-visual">
        <div className="sample-image-container">
          {title == enlargedCollection && enlargedSampleImage ?
            <Img src={enlargedSampleImage} />
            :
            <Img src={imageSrc} />
          }
        </div>
      </div>
      <div className="samples-content">
        <Wrapper>
          <header className="paragraph-max-width mb--l">
            <h2 className="fs--l mb--xs">{title}</h2>
            <div className="co--light lh--m mb--s">
              {description}
            </div>
            <Link title={title} to={linkResolver(meta)}>
              <UnderlineText text={t.samples.read_more} />
            </Link>
          </header>
          <div className="samples">
            <Grid>
              {samples}
            </Grid>
          </div>
        </Wrapper>
      </div>
    </div>
  )
}

export default CollectionSamples