import React, {useContext} from 'react'
import SpinnerSVG from '../images/spinner.inline.svg'
import StoreContext from '../context/StoreContext'
import { defaultLang } from '../../prismic-configuration'
import getTranslations from '../translations'

const SamplesCount = (props) => {
  const {adding, samplesAdded, lang} = props
  const t = getTranslations(lang)
  const context = useContext(StoreContext)
  const {setBasketVisibility} = context
  const handleClick = () =>{
    setBasketVisibility(true)
  }

  return(
    <div className="samples-count co--light" onClick={handleClick}>
      {adding ?
        <SpinnerSVG />
      :
        <span>{samplesAdded.length} {t.samples.fabric_in_basket}</span>
      }
    </div>
  )
}

export default SamplesCount