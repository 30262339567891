import React from 'react'
import Wrapper from './Wrapper'
import SamplesNav from './SamplesNav'
import SamplesCount from './SamplesCount'

const SamplesHeader = (props) => {
  const {samples, adding, samplesAdded, lang} = props
  return(
    <header className="samples-header">
      <Wrapper>
          <SamplesNav samples={samples} />
          <SamplesCount adding={adding} lang={lang} samplesAdded={samplesAdded} />
      </Wrapper>
    </header>
  )
}

export default SamplesHeader