import React from "react"
import { defaultLang } from "../../prismic-configuration";
import getTranslations from "../translations";

const SamplesHero = props => {
  const { lang } = props
  const t = getTranslations(lang)

  return (
    <header className="mb--xxl">
      <div className="samples-header-inner">
        <h1 className="fs--xl lh--s">
          { t.samples.title }
        </h1>
      </div>
    </header>
  )
}

export default SamplesHero
