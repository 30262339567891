import React, { useState, useContext, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import get from "lodash/get"
import btoa from "btoa"
import StoreContext from "../context/StoreContext"
import Sample from "../components/Sample"
import CollectionSamples from "../components/CollectionSamples"
import SamplesHeader from "../components/SamplesHeader"
import SamplesHero from "../components/SamplesHero"
import { linkResolver } from "../prismic/linkResolver"
import ParseSEOData from "../components/ParseSEOData"

export const query = graphql`
  query allSamplesQuery($lang: String) {
    prismic {
      allSampless(lang: $lang) {
        edges {
          node {
            meta_description
            meta_title
            share_image
            _meta {
              type
              uid
              lang
            }
          }
        }
      }
      allCollections(sortBy: rank_ASC, lang: $lang) {
        edges {
          node {
            samples {
              sample
            }
            _meta {
              type
              uid
              lang
            }
            title
            description
            featured_image_portrait
            featured_image_landscape
          }
        }
      }
    }
  }
`

const Samples = ({ data }) => {
  const doc = data.prismic.allSampless.edges.slice(0, 1).pop()
  const lang = doc?.node?._meta.lang

  const [enlargedSampleImage, setEnlargedSampleImage] = useState(null)
  const [enlargedCollection, setEnlargedCollection] = useState(null)
  const [samplesAdded, setSamplesAdded] = useState([])
  const context = useContext(StoreContext)

  useEffect(() => {
    const itemsArray = []
    context.checkout.lineItems.forEach(item => {
      if (Math.round(item.variant.price.amount) === 0) {
        itemsArray.push(item.variant.id)
      }
    })
    setSamplesAdded(itemsArray)
  }, [context])

  const addSampleToCart = variantId => {
    if (!samplesAdded.includes(variantId) && samplesAdded.length < 6) {
      context.addVariantToCart(variantId, 1)
    }
  }

  const removeSampleFromCart = variantId => {
    if (samplesAdded.includes(variantId)) {
      context.checkout.lineItems.forEach(item => {
        if (item.variant.id === variantId) {
          context.removeLineItem(context.client, context.checkout.id, item.id)
        }
      })
    }
  }

  return doc ? (
    <>
      <ParseSEOData data={doc.node} />
      <StaticQuery
        query={query}
        render={data => {
          let samples = get(data, "prismic.allCollections.edges")
          samples = samples.filter(sample => sample.node._meta.lang === lang)
          if (!samples) return null
          return (
            <div className="samples">
              <SamplesHero lang={lang} />
              <SamplesHeader
                samples={samples}
                adding={context.adding}
                samplesAdded={samplesAdded}
                lang={lang}
              />
              {samples.map((sample, index) => {
                const title = get(sample, "node.title[0].text")
                const meta = get(sample, "node._meta")
                const currentCollection = title
                const description = RichText.render(
                  get(sample, "node.description"),
                  linkResolver
                )
                const samplesArray = get(sample, "node.samples")
                if (samplesArray.length === 0) {
                  return
                }
                const imageSrc = get(sample, "node.featured_image_portrait.url")
                let sampleVariants = []
                samplesArray.forEach((sampleItem, index) => {
                  if (get(sampleItem, "sample")) {
                    const variantApiId = btoa(
                      sampleItem.sample.variants[0].admin_graphql_api_id
                    )
                    let collectionColorTitle = null
                    let title = sampleItem.sample.title
                    let inCart = samplesAdded.includes(variantApiId)
                      ? true
                      : false
                    if (title) {
                      title = title.split(", ")
                      collectionColorTitle = title[1].split(" (")[0]
                      title = `${collectionColorTitle}`
                    }
                    sampleVariants.push(
                      <Sample
                        collection={currentCollection}
                        title={title}
                        sampleImgSrc={sampleItem.sample.image.src}
                        setEnlargedCollection={setEnlargedCollection}
                        setEnlargedSampleImage={setEnlargedSampleImage}
                        variantApiId={variantApiId}
                        addSampleToCart={addSampleToCart}
                        removeSampleFromCart={removeSampleFromCart}
                        inCart={inCart}
                        lang={lang}
                      />
                    )
                  }
                })
                return (
                  <CollectionSamples
                    title={get(sample, "node.title[0].text")}
                    description={description}
                    meta={meta}
                    imageSrc={imageSrc}
                    samples={sampleVariants}
                    enlargedCollection={enlargedCollection}
                    enlargedSampleImage={enlargedSampleImage}
                    lang={lang}
                  />
                )
              })}
            </div>
          )
        }}
      />
    </>
  ) : (<></>)
}

Samples.query = query
export default Samples
