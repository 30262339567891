import React, {useState,useEffect} from 'react'
import CheckSVG from '../images/check.inline.svg'
import ProductImg from '../components/ProductImage'
import { defaultLang } from '../../prismic-configuration'
import getTranslations from '../translations'

const Sample = (props) => {
  const {sampleImgSrc, title, collection, setEnlargedCollection, setEnlargedSampleImage, variantApiId, addSampleToCart, removeSampleFromCart, inCart, lang} = props
  const t = getTranslations(lang)
  const [checked, setChecked] = useState(false)
  const sampleImgSrcThumb = sampleImgSrc.replace(/(\.[^\.]*)?$/, '_small$1')
  useEffect(()=>{
    if(checked){
      addSampleToCart(variantApiId)
    }else{
      removeSampleFromCart(variantApiId)
    }
  },[checked])

  useEffect(()=>{
    if(inCart === true){
      setChecked(true)
    }
  },[inCart])


  return(
    <label className="sample"
      key={variantApiId}
      onMouseEnter={()=>{
        setEnlargedSampleImage(sampleImgSrc)
        setEnlargedCollection(collection)
      }}
      onMouseLeave={()=>{
        setEnlargedSampleImage(null)
        setEnlargedCollection(null)
      }}
    >
      <input type="checkbox" name={title}
      onChange={()=>{
        setChecked(!checked)}
      }
      checked={checked}
      />
      <div className="card">
        <header className="sample-info mb--s">
          <div>
            <ProductImg src={sampleImgSrcThumb}/>
          </div>
          <div>
            <h4 className="lh--s">{title}</h4>
            <h5 className="fs--xs co--light lh--s mb--xs">{collection.trim()}, {t.samples.free_sample}</h5>
          </div>
        </header>
        <footer className="fs--s lh--s">
          <div className="checkmark">
            <CheckSVG />
          </div>
          <span>{ t.samples.add_to_basket }</span>
        </footer>
      </div>
    </label>
  )
}

export default Sample