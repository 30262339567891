import React from 'react'
import get from 'lodash/get'
import { navigate } from '@reach/router';

const SamplesNav = (props) => {
  const {samples} = props

  return(
    <nav className="samples-nav">
      <ul>
        {samples.map((sample, index)=>{
          const title = get(sample, 'node.title[0].text')
          const uid = get(sample, 'node._meta.uid')
          return(
            <li><a onClick={(event)=>{
              event.preventDefault()
              navigate(`#${uid}`)
            }}href={`#${uid}`} title={title}>{title}</a></li>
          )
        })}
      </ul>
    </nav>
  )
}

export default SamplesNav